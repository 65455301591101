export const SUCCESS_FETCH_KONFIRMASI = 'SUCCESS_FETCH_KONFIRMASI';
export const FAIL_FETCH_KONFIRMASI = 'FAIL_FETCH_KONFIRMASI';
export const SUCCESS_FETCH_KONFIRMASI_VERIFIKASI = 'SUCCESS_FETCH_KONFIRMASI_VERIFIKASI';
export const FAIL_FETCH_KONFIRMASI_VERIFIKASI = 'FAIL_FETCH_KONFIRMASI_VERIFIKASI';
export const SUCCESS_SUBMIT_KONFIRMASI = 'SUCCESS_SUBMIT_KONFIRMASI';
export const FAIL_SUBMIT_KONFIRMASI = 'FAIL_SUBMIT_KONFIRMASI';
export const SUCCESS_CANCEL_KONFIRMASI = 'SUCCESS_CANCEL_KONFIRMASI';
export const FAIL_CANCEL_KONFIRMASI = 'FAIL_CANCEL_KONFIRMASI';
export const SUCCESS_UPDATE_KONFIRMASI_VERIFIKASI = 'SUCCESS_UPDATE_KONFIRMASI_VERIFIKASI';
export const FAIL_UPDATE_KONFIRMASI_VERIFIKASI = 'FAIL_UPDATE_KONFIRMASI_VERIFIKASI';
export const SUCCESS_SET_COLLAPSE_ACTIVE = 'SUCCESS_SET_COLLAPSE_ACTIVE';
export const FAIL_SET_COLLAPSE_ACTIVE = 'FAIL_SET_COLLAPSE_ACTIVE';