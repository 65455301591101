export const SUCCESS_SHOW_PROFILE_VENDOR = 'SUCCESS_SHOW_PROFILE_VENDOR';
export const FAIL_SHOW_PROFILE_VENDOR = 'FAIL_SHOW_PROFILE_VENDOR';
export const SUCCESS_FETCH_PROFILE_VENDOR = 'SUCCESS_FETCH_PROFILE_VENDOR';
export const FAIL_FETCH_PROFILE_VENDOR = 'FAIL_FETCH_PROFILE_VENDOR';
export const SUCCESS_UPDATE_PROFILE_VENDOR = 'SUCCESS_UPDATE_PROFILE_VENDOR';
export const FAIL_UPDATE_PROFILE_VENDOR = 'FAIL_UPDATE_PROFILE_VENDOR';
export const SUCCESS_VENDOR_ADDRESS = 'SUCCESS_VENDOR_ADDRESS';
export const FAIL_VENDOR_ADDRESS = 'FAIL_VENDOR_ADDRESS';
export const SUCCESS_UPDATE_VENDOR_ADDRESS = 'SUCCESS_UPDATE_VENDOR_ADDRESS';
export const FAIL_UPDATE_VENDOR_ADDRESS = 'FAIL_UPDATE_VENDOR_ADDRESS';
export const SUCCESS_SHOW_VENDOR_SUMMARY = 'SUCCESS_SHOW_VENDOR_SUMMARY';
export const FAIL_SHOW_VENDOR_SUMMARY = 'FAIL_SHOW_VENDOR_SUMMARY';
export const SUCCESS_DOWNLOAD_SLK = 'SUCCESS_DOWNLOAD_SLK';
export const FAIL_DOWNLOAD_SLK = 'FAIL_DOWNLOAD_SLK';