export const SUCCESS_FETCH_VERIFIKASI_LIST_VENDOR = 'SUCCESS_FETCH_VERIFIKASI_LIST_VENDOR';
export const FAIL_FETCH_VERIFIKASI_LIST_VENDOR = 'FAIL_FETCH_VERIFIKASI_LIST_VENDOR';
export const SUCCESS_SHOW_VERIFIKASI_ITEM_VENDOR = 'SUCCESS_SHOW_VERIFIKASI_ITEM_VENDOR';
export const FAIL_SHOW_VERIFIKASI_ITEM_VENDOR = 'FAIL_SHOW_VERIFIKASI_ITEM_VENDOR';
export const SUCCESS_SAVE_VERIFIKASI_ITEM_VENDOR = 'SUCCESS_SAVE_VERIFIKASI_ITEM_VENDOR';
export const FAIL_SAVE_VERIFIKASI_ITEM_VENDOR = 'FAIL_SAVE_VERIFIKASI_ITEM_VENDOR';
export const SUCCESS_SAVE_VERIFIKASI_PER_LINE_ITEM_VENDOR = 'SUCCESS_SAVE_VERIFIKASI_PER_LINE_ITEM_VENDOR';
export const FAIL_SAVE_VERIFIKASI_PER_LINE_ITEM_VENDOR = 'FAIL_SAVE_VERIFIKASI_PER_LINE_ITEM_VENDOR';
export const SUCCESS_DELETE_VERIFIKASI_PER_LINE_ITEM_VENDOR = 'SUCCESS_DELETE_VERIFIKASI_PER_LINE_ITEM_VENDOR';
export const FAIL_DELETE_VERIFIKASI_PER_LINE_ITEM_VENDOR = 'FAIL_DELETE_VERIFIKASI_PER_LINE_ITEM_VENDOR';
export const SUCCESS_SHOW_LAST_VERIFIKASI = 'SUCCESS_SHOW_LAST_VERIFIKASI';
export const FAIL_SHOW_LAST_VERIFIKASI = 'FAIL_SHOW_LAST_VERIFIKASI';
export const SUCCESS_SHOW_LAST_VERIFIKASI_FOR_VERIFICATOR = 'SUCCESS_SHOW_LAST_VERIFIKASI_FOR_VERIFICATOR';
export const FAIL_SHOW_LAST_VERIFIKASI_FOR_VERIFICATOR = 'FAIL_SHOW_LAST_VERIFIKASI_FOR_VERIFICATOR';
export const SUCCESS_SHOW_LOG_HISTORY_VERIFIKASI = 'SUCCESS_SHOW_LOG_HISTORY_VERIFIKASI';
export const FAIL_SHOW_LOG_HISTORY_VERIFIKASI = 'FAIL_SHOW_LOG_HISTORY_VERIFIKASI';