export const SUCCESS_FETCH_LIST_AUCTION = 'SUCCESS_FETCH_LIST_AUCTION';
export const FAIL_FETCH_LIST_AUCTION = 'FAIL_FETCH_LIST_AUCTION';
export const SUCCESS_SHOW_DETAIL_PARAMETER_ITEM_AUCTION = 'SUCCESS_SHOW_DETAIL_PARAMETER_ITEM_AUCTION';
export const FAIL_SHOW_DETAIL_PARAMETER_ITEM_AUCTION = 'FAIL_SHOW_DETAIL_PARAMETER_ITEM_AUCTION';
export const SUCCESS_STORE_DETAIL_AUCTION = 'SUCCESS_STORE_DETAIL_AUCTION';
export const FAIL_STORE_DETAIL_AUCTION = 'FAIL_STORE_DETAIL_AUCTION';
export const SUCCESS_UPDATE_ITEM_DETAIL_AUCTION = 'SUCCESS_UPDATE_ITEM_DETAIL_AUCTION';
export const FAIL_UPDATE_ITEM_DETAIL_AUCTION = 'FAIL_UPDATE_ITEM_DETAIL_AUCTION';
export const SUCCESS_STORE_SCHEDULE_ITEM_DETAIL_AUCTION = 'SUCCESS_STORE_SCHEDULE_ITEM_DETAIL_AUCTION';
export const FAIL_STORE_SCHEDULE_ITEM_DETAIL_AUCTION = 'FAIL_STORE_SCHEDULE_ITEM_DETAIL_AUCTION';
export const SUCCESS_STORE_PUBLISH_ITEM_DETAIL_AUCTION = 'SUCCESS_STORE_PUBLISH_ITEM_DETAIL_AUCTION';
export const FAIL_STORE_PUBLISH_ITEM_DETAIL_AUCTION = 'FAIL_STORE_PUBLISH_ITEM_DETAIL_AUCTION';
export const SUCCESS_STORE_PESERTA_ITEM_AUCTION = 'SUCCESS_STORE_PESERTA_ITEM_AUCTION';
export const FAIL_STORE_PESERTA_ITEM_AUCTION = 'FAIL_STORE_PESERTA_ITEM_AUCTION';
export const SUCCESS_GET_HISTORY_ITEM_AUCTION = 'SUCCESS_GET_HISTORY_ITEM_AUCTION';
export const FAIL_GET_HISTORY_ITEM_AUCTION = 'FAIL_GET_HISTORY_ITEM_AUCTION';
export const SUCCESS_GET_HISTORY_VENDOR_AUCTION = 'SUCCESS_GET_HISTORY_VENDOR_AUCTION';
export const FAIL_GET_HISTORY_VENDOR_AUCTION = 'FAIL_GET_HISTORY_VENDOR_AUCTION';
export const SUCCESS_GET_DOCUMENT_HISTORY_VENDOR_AUCTION = 'SUCCESS_GET_DOCUMENT_HISTORY_VENDOR_AUCTION';
export const FAIL_GET_DOCUMENT_HISTORY_VENDOR_AUCTION = 'FAIL_GET_DOCUMENT_HISTORY_VENDOR_AUCTION';
export const SUCCESS_GET_HISTORY_TABULATION_ITEM_AUCTION = 'SUCCESS_GET_HISTORY_TABULATION_ITEM_AUCTION';
export const FAIL_GET_HISTORY_TABULATION_ITEM_AUCTION = 'FAIL_GET_HISTORY_TABULATION_ITEM_AUCTION';
export const SUCCESS_DELETE_ITEM_AUCTION = 'SUCCESS_DELETE_ITEM_AUCTION';
export const FAIL_DELETE_ITEM_AUCTION = 'FAIL_DELETE_ITEM_AUCTION';
export const SUCCESS_BANNED_VENDOR_AUCTION = 'SUCCESS_BANNED_VENDOR_AUCTION';
export const FAIL_BANNED_VENDOR_AUCTION = 'FAIL_BANNED_VENDOR_AUCTION';
export const SUCCESS_GET_DOWNLOAD_HISTORI_AUCTION = 'SUCCESS_GET_DOWNLOAD_HISTORI_AUCTION';
export const FAIL_GET_DOWNLOAD_HISTORI_AUCTION = 'FAIL_GET_DOWNLOAD_HISTORI_AUCTION';
export const SUCCESS_PAUSE_RESUME_AUCTION = 'SUCCESS_PAUSE_RESUME_AUCTION';
export const FAIL_PAUSE_RESUME_AUCTION = 'FAIL_PAUSE_RESUME_AUCTION';
export const SUCCESS_FETCH_CHAT_AUCTION = 'SUCCESS_FETCH_CHAT_AUCTION';
export const FAIL_FETCH_CHAT_AUCTION = 'FAIL_FETCH_CHAT_AUCTION';
export const APPEND_CHAT_AUCTION = 'APPEND_CHAT_AUCTION';
export const UNREAD_CHAT_AUCTION_BUYER = 'UNREAD_CHAT_AUCTION_BUYER';
export const READ_CHAT_AUCTION_BUYER = 'READ_CHAT_AUCTION_BUYER';
export const UNREAD_CHAT_AUCTION_VENDOR = 'UNREAD_CHAT_AUCTION_VENDOR';
export const READ_CHAT_AUCTION_VENDOR = 'READ_CHAT_AUCTION_VENDOR';
export const CLEAR_CHAT_AUCTION_VENDOR = 'CLEAR_CHAT_AUCTION_VENDOR';
export const SUCCESS_GET_HISTORY_CHAT_AUCTION = 'SUCCESS_GET_HISTORY_CHAT_AUCTION';
export const FAIL_GET_HISTORY_CHAT_AUCTION = 'FAIL_GET_HISTORY_CHAT_AUCTION';
export const SUCCESS_TYPING_CHAT_AUCTION = 'SUCCESS_TYPING_CHAT_AUCTION';
export const FAIL_TYPING_CHAT_AUCTION = 'FAIL_TYPING_CHAT_AUCTION';